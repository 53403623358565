export enum LoadingState {
  INIT = 'INIT',
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  FAILED = 'FAILED',
}

export type DeviceType = {
  description: string;
  hardware_provider: string;
  id: number;
  name: string;
  revision_no: string;
  version_no: string;
};

export type DeviceState = {
  description: string;
  id: number;
  name: string;
};

export type DeviceHistoryType = {
  description: string;
  id: number;
  name: string;
};

export type OrganizationType = {
  description: string | null;
  id: number;
  name: string;
};

export type TimeType = {
  description: string;
  id: number;
  name: string;
};

// fixed enum values
export enum TimeTypeEnum {
  Primary = 1,
  Secondary = 2,
  School = 3,
  Service = 4,
  Closed = 5,
}

export type BookingProvider = {
  description: string | null;
  id: number;
  name: string;
  can_get_activity_types: boolean;
  can_get_associations: boolean;
  can_get_locations: boolean;
  get_bookings_requires_location_ids: boolean;
};

export type AreaType = {
  description: string;
  id: number;
  name: string;
};

export type TimeZone = {
  name: string;
};

export type CreatedFrom = {
  description: string;
  id: number;
  name: string;
};

export type CreatedFromType = {
  id: number;
  name: string;
};

export type DepartmentType = {
  description: string;
  id: number;
  name: string;
};

export type CountryRegion = {
  id: number;
  ISO_name: string;
  name: string;
};

export type LicensePackage = {
  id: number;
  name: string;
  description: string;
};

export type LicenseTransaction = {
  id?: number;
  license_package_id: number;
  license_package_name?: string;
  organization_id: number;
  organization_name?: string;
  start_date: string;
  end_date: string;
  created_at?: string;
};

export type AdditionalLicenseTransactionFeature = {
  id: number;
  organization_id: number;
  organization_name?: string;
  license_feature_id: number;
  license_feature_name?: string;
  amount_of_license_feature: number;
  delta_license_feature: number;
  active: boolean;
  start_date: string;
  alternative_end_date?: string;
  created_at: string;
};

export type LicenseFeature = {
  id: number;
  name: string;
  description: string;
};

export type RolePackage = {
  id: number;
  name: string;
  description: string | null;
  loadingState?: LoadingState;
};

export type MasterActivity = {
  country_region_id: number;
  description: string | null;
  id: number;
  name: string;
  parent_id: number | null;
};

export type FacilityType = {
  id: number;
  name: string;
  description?: string;
};

export type FacilityProvider = {
  id: number;
  name: string;
  description: string;
  country_region_id: number;
};

export enum FacilityProviderEnum {
  FacilitetsDatabasen = 1,
}

export type ServiceResult = {
  Success: boolean;
  StatusMessage?: string;
  ErrorMessage?: string;
};

export type Organization = {
  id: number;
  name: string;
  organization_type_id: number;
  organization_type_name: string;
  country_region_id: number;
  country_name: string;
};

export type DropdownOption = { name: string; value: any };

export type CognitoToken = {
  auth_time: number;
  at_hash: string;
  aud: string;
  authorized_roles: string;
  authorized_routes: string;
  country_region_id: string;
  country_region_name: string;
  'custom:language':string;
  'cognito:username': string;
  disabled_ui: string;
  email: string;
  event_id: string;
  exp: number;
  hidden_ui: string;
  iat: number;
  iss: string;
  jti: string;
  license_package_id: string;
  license_package_name: string;
  name: string;
  organization_id: string;
  organization_name: string;
  sub: string;
  token_use: string;
  user_language: string;
};

export type TokenUser = CognitoToken & {
  isSystemAdmin: boolean;
  nameWithRole: string;
};

export type JobRateUnit = {
  id: number;
  name: string;
  description?: string;
};

export type DataProvider = {
  id: number;
  name: string;
};

export enum LicensePackageEnum {
  Standard = 1,
  Advanced = 2,
  Enterprise = 3
}

export enum LicenseFeatureEnum {
  RolePackage_Admin = 1000,
  RolePackage_User = 1001,
  RolePackage_Insight_User = 1002,
  Association_Users = 1003,

  Integration = 2000,

  Device = 3000,
  External_Device = 3001,

  Facility = 4000,

  Images_Per_Hour_4 = 5000,
  Images_Per_Hour_8 = 5001,
  Images_Per_Hour_12 = 5002,

  Areas = 6000,

  AreaGroups = 7000,

  Department = 8000,

  Insights = 9000,
  Insights_Full = 9001,
  Insights_Sessions = 9010,

  API = 10000
}
