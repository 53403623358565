import { Component, Input } from '@angular/core';

export enum IconStatus {
  ACTIVE = 'Active',
  SUCCESS = 'Success',
  INACTIVE = 'Inactive',
  FAILURE = 'Failure',
  PENDING = 'Pending',
  INPROGRESS = 'InProgress',
  DISABLED = 'Disabled',
  IDLE = 'Idle',
  NONE = 'No detections',
  GREEN = 'Devices online',
  YELLOW = '1-2 hours since activitity',
  RED = 'More then 2 hours since last activity',
}

@Component({
  selector: 'app-status-icon',
  templateUrl: './status-icon.component.html',
  styleUrl: './status-icon.component.scss',
})
export class StatusIconComponent {
  @Input() value: IconStatus = IconStatus.ACTIVE;

  public IconStatus = IconStatus;

  getKeyForStyling(value:string ) {
    return Object.keys(IconStatus)[Object.values(IconStatus).indexOf(value as unknown as IconStatus)].toLowerCase();
  }
}
