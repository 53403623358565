import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-form-week-calendar',
  templateUrl: './form-week-calendar.component.html',
  styleUrl: './form-week-calendar.component.scss',
})
export class FormWeekCalendarComponent implements OnInit {
  @Input() placeholder = '';
  @Input() errorLabel = '';
  @Input() control: FormControl = new FormControl();
  @Input() initialDate?: Date;
  @Input() tooltip?: string;
  @Input() readOnly = false;

  @Output() valueChange = new EventEmitter<string>();

  visualDate: string = '';

  ngOnInit() {
    this.visualDate = this.initialDate?.toDateString() ?? '';
    this.getWeekNumberFromDate();
  }

  public valueChanged() {
    const UTCTime = new Date(new Date(this.visualDate).toUTCString()).toISOString();
    this.valueChange.emit(UTCTime);
    this.getWeekNumberFromDate();
  }

  public reset() {
    this.visualDate = this.initialDate?.toDateString() ?? '';
    this.valueChanged();
  }

  public getWeekNumberFromDate() {
    const date = new Date(this.visualDate);
    const weekNumber = this.getIsoWeekNumber(date);
    this.visualDate = `Week ${weekNumber}`;
  }

  //ChatGPT o1 fix
  /**
   * Calculates the ISO 8601 week number for the specified date.
   *
   * According to ISO 8601, weeks start on Monday, and Week 1 is the week
   * containing the first Thursday of January.
   *
   * This method shifts the given date to the corresponding Thursday, finds
   * the first Thursday in January of the same year, and determines how many
   * weeks have passed between the two.
   *
   * @param date The date for which the ISO week number is to be calculated.
   * @returns The ISO 8601 week number for the given date.
   */
  private getIsoWeekNumber(date: Date): number {
    const tempDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));

    const dayOfWeek = tempDate.getUTCDay();
    // Move 'tempDate' forward to the Thursday of the same ISO week.
    const diffToThursday = dayOfWeek <= 0 ? 4 : 4 - dayOfWeek;
    tempDate.setUTCDate(tempDate.getUTCDate() + diffToThursday);

    // Obtain the first Thursday of the year by starting at January 4thand moving forward or backward to Thursday.
    const firstThursday = new Date(Date.UTC(tempDate.getUTCFullYear(), 0, 4));
    const firstThursdayDay = firstThursday.getUTCDay();
    const diffToFirstWeekThursday = firstThursdayDay <= 0 ? 4 : 4 - firstThursdayDay;
    firstThursday.setUTCDate(firstThursday.getUTCDate() + diffToFirstWeekThursday);

    // Calculate the difference in weeks between the two Thursdays.
    const msInAWeek = 7 * 24 * 60 * 60 * 1000;
    return 1 + Math.round((tempDate.getTime() - firstThursday.getTime()) / msInAWeek);
  }
}
