import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { catchError, throwError } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private messageService: MessageService, private tr: TranslateService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler) {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if(error.error.errorMessage) {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: error.error.errorMessage, // TODO: Change fixed error string to transladed version in backend.
            life: 30000, // 30 seconds
          });
        } else {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: this.tr.instant('error_message_generic'),
            life: 30000, // 30 seconds
          });
        }
        return throwError(() => new Error(error.message));
      })
    );
  }
}
