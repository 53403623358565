

<i
  [pTooltip]="value"
  tooltipPosition="top"
  [class]="'pi ' + getKeyForStyling(value)"
  [ngClass]="{
    'pi-check': value === IconStatus.ACTIVE || value === IconStatus.SUCCESS || value === IconStatus.GREEN,
    'pi-info': value === IconStatus.INACTIVE || value === IconStatus.FAILURE || value === IconStatus.RED || value === IconStatus.YELLOW,
    'pi-hourglass': value === IconStatus.PENDING || value === IconStatus.INPROGRESS,
    'pi-circle-off': value === IconStatus.DISABLED,
    'pi-minus-circle': value === IconStatus.IDLE || value === IconStatus.NONE,
  }"
>
</i>
