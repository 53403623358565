import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TreeNode } from 'primeng/api';
import { TableAction, TableColumn } from '../table/table.component';

@Component({
  selector: 'app-tree-table',
  templateUrl: './tree-table.component.html',
  styleUrls: ['./tree-table.component.scss'],
})

export class TreeTableComponent {
  @Input() data: TreeNode[] = [];
  @Input() columns: TableColumn[] = [];
  @Input() loading = false;
  @Input() selectable = false;
  @Input() emptyMessage = this.tr.instant('shared.table.emptyMessage');
  @Input() loadingMessage = this.tr.instant('shared.table.loadingMessage');
  @Input() actions: TableAction[] = [];
  @Input() expandable = false;

  @Output() rowSelected = new EventEmitter();
  @Output() selection = new EventEmitter();

  selectedItems: unknown[] = [];

  constructor(private tr: TranslateService) { }

  onRowContextSelect(event: unknown) {
    this.rowSelected.emit(event);
  }

  onSelection() {
    this.selection.emit(this.selectedItems);
  }
}
